
/* mobile screen  */
@media screen and (min-width: 320px) and (max-width: 780px) {
    .companySeetingTab .ant-tabs-nav {
        height: auto !important;
    }  
    .max-width{
        height: auto !important;
    } 
   
    .trial-box{
        width: auto !important;
    }
    .img-wrapper{
        width: auto !important;
    }
    .tablecell{
        overflow-x: scroll;
    }
    .companysearchbtn .ant-col.ant-form-item-control{
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
    }
    .companysearchbtn .ant-form-item-control-input, .companysearchbtn button{
        width: 100% !important;
    }
    
    .user-form{
        display: block;
    }
   
    .company-form{
        display: block;
    }
    .company-form .ant-select, .company-form .ant-input, .user-form .ant-select, .user-form .ant-input{
        width: 100% !important;
    }
    .company-add-account{
        display: block;
        padding-bottom: 0px;
    }
    .company-add-account button, .user-form button {
      width: 100% !important;
    }
    .companySeetingTab .ant-tabs-ink-bar {
        right: auto !important;
        left: auto !important;
    }
    .company-detail .ant-descriptions-bordered .ant-descriptions-row{
        display: flex;
        flex-direction: column;

    }
    .login-data{
        padding: 20px !important;
    }
    .companySeetingTab .ant-tabs-ink-bar{
        background: transparent !important;
    }
    .companySeetingTab .ant-tabs-nav-list{
       padding: unset !important;
       height: auto !important;
    }
    .companySeetingTab .ant-descriptions-item-label{
        width: 100% !important
    }
    .ant-layout-sider-collapsed{
        flex: 0 0 0px;
        max-width: 80px !important;
        min-width: 80px !important;
        width: 0px;
    }
    .ant-layout-sider-collapsed .logo {
        height: 40px !important;
    }
    .ant-layout-sider-collapsed ul li{
        padding: 0 calc(50% - 25px / 2) !important;
        margin-bottom: 20px !important;
    }
    .version{
        display: none;
    }
    .image-width{
        width: 100%;
    }
    .manageData .ant-col-xs-24{
        padding: 7px;
    }
    .responsive{
        display: grid !important;
        grid-template-columns: 20% 70% 10% !important;
    }
    .user-req-list li.ant-list-item{
        flex-direction: column;
    }
    .user-req-list .ant-list-item-meta , .user-req-list ul.ant-list-item-action{
        width: 100%;
        margin-top: 5px;
    }
    .inviteIMG{
        height: auto !important;
        width: 100%;
    }
    .invite-user .dashboard-card-header{
        padding-left: 0 !important;
    }
    .invite-user .userTab{
        margin-left: 0 !important;
    }
    .ant-drawer-content-wrapper{
        width: 100% !important;
    }
    .editcompany .footer-buttons{
        justify-content: flex-start !important;
    }
    .editcompany .footer-buttons button{
        width: auto !important;
    }
    .manageData .ant-select{
        width: 100% !important;
    }
    .footer{
        bottom: 8px !important;
        font-size: 13px !important;
        line-height: 18px;
    }
    .desc-wrapper{
        font-size: 23px !important;
        font-weight: 600 !important;
    }
    /* .responsive-width{
        width: 280px;
    } */
}
/* ipad  */
@media only screen and (min-width: 768px) and (max-width: 1024px){
    .max-width{
        height: auto !important;
    }
    .invite-user .ant-col-md-8{
        display: block;
        flex: 0 !important;
        max-width: fit-content !important;
    }
    .user-form{
        display: flex;
        justify-content: center;
    }
    .company-form{
        display: flex;
        justify-content: flex-end;
    }
    .company-add-account{
        display: flex;
    }
    .login-bg{
    display: none;
    flex: 0 0 50%;
    max-width: 100%;
    }
    .companySeetingTab .ant-tabs-nav-list{
        width: 100% !important;
        display: flex;
        justify-content: flex-start;
    }
    .company-add-account button, .user-form button {
        width: auto !important;
      }
}