.row-header {
  font-weight: 700;
  font-size: 14px;
  color: #303030;
  font-family: "Matter-bold";
}

.col-header {
  font-weight: 600;
  font-size: 14px;
  color: #416b88;
}

.billing-wrapper .ant-table-thead {
  display: none;
}

/* Why THis is Added ? prthvi needs to review  */
.billing-wrapper tr[data-row-key="Targets"] {
  vertical-align: top;
}

.billing-wrapper tr[data-row-key="Targets"] .ant-table-cell:nth-child(1) {
  vertical-align: middle;
}
.billing-wrapper tr[data-row-key="Pricings"] .ant-table-cell:nth-child(3),
.billing-wrapper tr[data-row-key="Pricings"] .ant-table-cell:nth-child(2) {
  vertical-align: top;
}

.billing-wrapper .bg-light-blue {
  background-color: #dbe8f4;
}
/* EDF4FA */
.plan-user {
  color: #416b88;
  font-weight: 600;
  font-size: 16px;
  font-family: "Matter-bold";
}

.plan-month {
  color: #8eb6d3;
  font-weight: 600;
  font-size: 16px;
  font-family: "Matter-bold";
}

.billing-wrapper tr.ant-table-row-level-0:hover > td {
  background: unset;
}

.billing-wrapper .ant-table {
  font-size: 14px;
}

.billing-wrapper .ant-table-cell {
  padding: 12px 10px !important;
}
.billing-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border-right: 2px solid #dbe8f4;
}
.billing-wrapper .ant-table-tbody > tr > td {
  border-bottom: 2px solid #dbe8f4;
}

.billing-wrapper .ant-table.ant-table-bordered > .ant-table-container {
  border-left: 0px !important;
}

.billing-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table {
  border-top: 0px !important;
}

.billing-wrapper tr td:nth-child(1) {
  border-right: 2px solid #dbe8f4 !important;
}

.billing-wrapper tr td:nth-last-child(1) {
  border-right: 0 !important;
}

.billing-wrapper tr:nth-last-child(1) td {
  border-bottom: 0;
}
.tooltip-text {
  font-size: 11.8px !important;
}
.header-style .ant-modal-header {
  border-radius: 7px !important;
}
.header-style .ant-modal-content {
  padding-bottom: 15px !important;
  padding-right: 2px !important;
}
.ant-modal.header-style {
  padding: 80px !important;
}
.tick.anticon {
  line-height: 36px !important;
}
.box-card {
  display: flex !important;
  padding: 0px !important;
}
.checkoutlined {
  color: #416b88 !important;
  font-size: 16px !important;
}
.typogrphy-text {
  /* margin-left: 9px !important; */
  font-size: 16px !important;
}
.contact-btn {
  height: auto !important;
  width: auto !important;
  background-color: #416b88 !important;
  color: white !important;
  font-size: 18px !important;
  padding: 9px 40px !important;
  font-weight: 500 !important;
  font-family: "Matter-bold";
}
.manage-billing-text {
  margin-bottom: 0px;
  color: #8eb6d3;
  font-weight: 600;
  font-size: 16px !important;
}
.trail-wrapper {
  font-size: 16px;
  font-weight: 600;
  color: black;
  display: flex;
  justify-content: center;
  font-style: italic;
}
.desc-wrapper {
  color: #416b88;
  font-size: 28px;
  font-weight: 600;
  font-family: "Matter-bold";
}
.th-class .ant-table-thead > tr > th {
  padding: 0px !important;
}
.header-text {
  font-size: 20px;
  color: #416b88;
  display: flex;
  justify-content: center;
  font-family: "Matter-bold";
  margin-top: 29px;
  margin-bottom: 30px;
}
.div-wrapper {
  display: flex;
  flex-direction: column;
}
.text-wrapper-data {
  font-size: 20px;
  font-family: "Matter-bold";
  margin-top: 20px;
}
.detail-data {
  font-size: 16px;
  color: #727272;
  line-height: 20px;
  margin-top: 10px;
}
.btn-class {
  font-size: 18px;
  color: #303030;
}
.btn-class-css {
  border-color: #303030;
  height: auto;
  padding: 6px 15px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
  color: #303030;
}
.btn-class-css.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  border-color: #416b88 !important;
  background-color: #416b88 !important;
  color: white;
}
.choose-btn.ant-btn[disabled],
.choose-btn.ant-btn[disabled]:hover,
.choose-btn.ant-btn[disabled]:focus,
.choose-btn .ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
  text-shadow: none !important;
  box-shadow: none !important;
  height: auto;
  padding: 6px 15px;
  font-size: 18px;
}

.choose-btn {
  border-color: #303030;
  height: auto;
  padding: 6px 15px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 15px;
  color: #303030;
}
.btn-class-css:hover {
  border-color: #303030;
  color: #303030;
}
.choose-btn:hover {
  border-color: #303030;
  color: #303030;
}
.start-text {
  font-size: 14px;
  color: #252525;
  margin-top: 10px;
}
.rs-css {
  color: #416b88;
  font-size: 26px;
  font-family: "Matter-bold";
  line-height: 1;
}
.rsd-css {
  color: #416b88;
  font-size: 16px;
  font-family: "Matter-bold";
}
.discount-text {
  color: #8eb6d3;
  font-size: 16px;
  font-family: "Matter-bold";
}
.trial-text {
  color: #727272;
  font-size: 16px;
  margin-top: 15px;
}
.trial1-text {
  color: #727272;
  font-size: 16px;
  margin-top: 15px;
}
.btn-touch-class-css {
  border-color: #416b88;
  height: auto;
  padding: 6px 15px;
  font-size: 18px;
  font-weight: 600;
  background: #416b88;
  color: white;
  margin-top: 15px;
}
.column-money {
  vertical-align: baseline !important;
}
.col-2-class {
  margin-left: 10px;
}
.heading-text {
  color: #416b88;
  font-size: 18px;
  font-family: "Matter-bold";
}
.bulletpoint .ant-list-item {
  border-bottom: none !important;
}
.contact-card {
  background-color: #edf4fa;
  margin-top: 30px;
  border-radius: 7px;
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 20px;
}
.text-card {
  margin-top: 20px;
  margin-left: 20px;
}
.spanData-text {
  font-size: 18px;
  font-family: "Matter-bold";
}
.btn-touch-class-css:hover {
  border-color: #416b88;
  color: #416b88;
}

/* .ant-table-cell-fix-left, */
