.guide-data{
    display: flex;
    justify-content: space-between;
    /* padding: 20px; */
  }
  .desc-css{
    font-size: 18px;
    font-weight: 600;
    margin-top: 24px !important;
    margin-left: 20px !important;
  }
  .text-desc{
    font-size: 18px;
    color: black !important;
    font-weight: 700;
    font-family: "Matter-bold";
    margin-left: 25px !important;
  }
  .searchbar .ant-input-wrapper{
    position: relative;
  }
  .searchbar input{
    padding-left: 30px;
    background: #EFF4F9;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 40px;
    font-size: 16px;
  }
  .searchbar .ant-input-search-button{
    border-radius: 8px !important;
   height: 100%;
  }
  .searchbar .ant-input-group-addon{
    position: absolute;
    left: 0;
    z-index: 90;
    top: 0;
    bottom: 0;
    margin: auto 0
  }
  .searchbar button{
    border: none;
    background: #EFF4F9;
    border-radius: 7px 0 0 7px;
  }
  .searchbar button:hover{
    background-color: #EFF4F9;
    cursor: pointer;
  }
  .table1 .ant-table-thead > tr > th{
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;
    background-color: #EFF4F9 !important;
    color: #416B88 !important;
    font-weight: 600;
    font-size: 16px;
  }
.table1 .ant-table-tbody > tr > td{
  font-size: 16px;
  /* padding-top: 20px;
  padding-bottom: 20px; */
  font-weight: 600;
}
.table1 .ant-table-thead > tr > th:nth-child(2){
  border-top-left-radius: 7px !important;
   border-top-right-radius: 7px !important;
  background-color: #416B88 !important;
  color: white !important;
  font-weight: 600;
  font-size: 16px;
}
.table2 .ant-table-tbody > tr > td{
  font-size: 16px;
  /* padding-top: 10px;
  padding-bottom: 10px; */
}
.table3 .ant-table-thead > tr > th{
  border-radius: 3px !important;
  background-color: #EFF4F9 !important;
  color: #416B88 !important;
  font-weight: 600;
  font-size: 16px;
}
.table3 .ant-table-tbody > tr > td{
font-size: 16px;
/* padding-top: 20px;
padding-bottom: 20px; */
}
.main-div{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.pencil-style{
  color: #CBCBCB;
}
.dasboard-text{
    font-size: 18px !important;
    font-weight: 700 !important;
    margin-left: 22px !important;
    font-family: "Matter-bold";
    color: #416B88 !important;
}
.dasboardData-text{
  font-size: 18px !important;
  font-weight: 700 !important;
  margin-left: 25px !important;
  margin-top: 15px !important;
  font-family: "Matter-bold";
  color: #416B88 !important;
}
.search-div{
    margin-top: 24px !important;
    margin-right: 20px !important;
    margin-bottom: 10px !important;
}
.plus-btn{
  margin-right: 21px;
  margin-bottom: 12px;
    /* margin: 10px 20px 10px 0px !important */
}
.btn-clr{
    background-color: #416B88;
    color: white;
    margin-top: 20px;
}
.btn-clr:hover{
    border-color: #416B88;
    color: #416B88;
}
.table1 .ant-table-container, .table1 table{
    border: none !important;
}
.table1 table{
  border-collapse: separate !important; 
  border-spacing: 20px 0 !important;
}
.table1 .ant-table-cell{
  border-left: 1px solid #f0f0f0 !important;
}
.ant-popover-message > .anticon{
  display: none;
}

.table-animate{
  animation: highlight-animate-head 3s ease-in-out;
  margin-top: 14px;
  padding-top: 3px;
  border-radius: 5px;
  transition: .3s;
}
.ant-popover-content{
  /* width: 86%; */
}
.ant-popover-buttons{
 display: flex;
}
@keyframes highlight-animate-head {
  0%{
    box-shadow: 0px 0px 0px rgb(66 107 136 / 58%);
  }
  20%{
    box-shadow: 0px 0px 16px rgb(66 107 136 / 58%);
  }
  40%{
    box-shadow: 0px 0px 13px rgb(66 107 136 / 58%);
  }
  60%{
    box-shadow: 0px 0px 10px rgb(66 107 136 / 58%);
  }
  80%{
    box-shadow: 0px 0px 7px rgb(66 107 136 / 58%);
  }
  100%{
    box-shadow: 0px 0px 0px rgb(66 107 136 / 58%);
  }
}
.ant-popover-message-title{
  font-size: 18px;
}

.wb-100{
  width: 100%;
  height: 40%;
  display: block;
}
.editIcon{
  margin-left: 10px;
}
.ant-popover-buttons button{
  width: 80px !important;
}
.no-data-wrap{
  margin-left: 25px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  font-family: 'Matter-bold';
  font-weight: 600;
}
.content{
  display: none;
}
.tab-clss.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before{
border-bottom: none !important;
}
.tabb-clss.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before{
  border-bottom: none !important;
  }
.tab-clss.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active{
  border-bottom-color: 1px solid #416B88 !important;
  border: 1px solid #416B88 !important;
 
  padding: 8px 13px;

}
.tabb-clss.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active{
  border-bottom-color: 1px solid #416B88 !important;
  border: 1px solid #416B88 !important;
 
  padding: 8px 13px;

}
.tab-clss .ant-tabs-tab:nth-child(3){
  margin-left: 0px !important;
  padding: 8px 13px !important;
  border-top-right-radius: 7px !important;
   border-bottom-right-radius: 7px !important;
}
.tab-clss .ant-tabs-tab:nth-child(1){
  padding: 8px 13px !important;
  border-top-left-radius: 7px !important;
   border-bottom-left-radius: 7px !important;
}
.tabb-clss .ant-tabs-tab:nth-child(1){
  padding: 8px 13px !important;
  border-top-left-radius: 7px !important;
   border-bottom-left-radius: 7px !important;
}
.tabb-clss .ant-tabs-tab:nth-child(2){
  margin-left: 0px !important;
  padding: 8px 13px !important;
  border-top-right-radius: 7px !important;
   border-bottom-right-radius: 7px !important;
}
.tab-clss .ant-tabs-tab:nth-child(2){
  margin-left: 0px !important;
}
.tab-clss.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab{
  padding: 8px 13px;
}
.text-define{
  font-size: 18px;
  margin-left: 22px;
  font-family: 'Matter-bold';
}