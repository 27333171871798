.info{
    position: relative;
    padding: 40px;
    display: flex;
    flex-direction: column;
    min-height: 580px;
}

.login-heading-text{
    color: #FFFFFF !important;
    top: 0;
    width: 100%;
    font-weight: 700;
    margin: 0;
    font-size: 38px;
}
.login-heading-text-font{
    color: #FFFFFF !important;
    top: 0;
    width: 100%;
    font-weight: 300;
    margin: 0;
}
.logo{
    top: 10%
}
.ant-form-item-label > label{
    color: #727272 !important;
}
.ant-modal-footer{
    padding: 0px !important;
    width: 0px !important;
    border: 0px !important;
  }
.ant-modal-content{
 border-radius: 12px !important;
}
.new-account-model{
    width: 402px !important;
    height: auto !important;
}
.ant-input{
 border-radius: 8px !important;
}
.ant-btn{
    border-radius: 8px;
}
.form-input{
 margin-top: 0px;
 color:"#727272";
 font-size: "18px";
}
.new-account-btn{
    color:white !important;
    background-color: #416B88 !important;
    height: "20px";
}
.slidermenu .ant-layout-sider-children{
    background-color: #416B88 !important;
}
.ant-input-password input{
    font-size: 18px;
}

.signup-btn{
    color: #416B88;
    font-weight: 600; 
    font-size: 18px;
    padding: 0 6px;
    font-style: italic !important;
}
.signup-btn:hover{
    background: transparent !important;
}