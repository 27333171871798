.download-report-card{
    background-color: #EFF4F9;
    border-radius: 7px;
    padding: 15px;
    display:flex;
    justify-content: space-between;
    font-size: 18px;
    align-items: center;
    
}
.dashboard-chart-skeleton{
    width: 24vw !important;
    height: 200px !important;
  }
.inputNumberSmall{
    font-size: 20px !important;
    border: none;
    background-color: #EFF4F9;
    color: #416B88 ;
    padding: 0 !important;
    font-weight: 600 !important;
    font-family: 'Matter-bold';
}
.inputNumberSmall-input{
    font-size: 20px !important;
    border: none;
    background-color: transparent;
    color: #416B88 ;
    padding: 0 !important;
    font-weight: 600 !important;
    font-family: 'Matter-bold';
}
.inputNumberSmall .ant-input-number-handler-wrap{
    display: none !important;
}
.inputNumberSmall .ant-input-number-input{
    font-weight: 600 !important;
    padding: 0;
    font-size: 20px;
    text-align: center;
    font-family: 'Matter-bold';
}
.table-data.ant-table-cell{
    padding: 0px !important;
}
.header-style .ant-modal-header{
    border-radius: 7px !important;
}
.header-style .ant-modal-content{
  padding-bottom: 15px !important;
  padding-right: 2px !important;
}
.webGradeHistory .ant-select-selector{
    border: none !important;
    background-color: transparent !important;
    color: black !important;
    font-size: 16px;
    margin-left: 8px;
}
/* .tablecell .ant-table-thead th:nth-child(1){
    border-radius: 7px 0 0 7px;
}

.tablecell .ant-table-thead th:nth-last-child(){
    border-radius: 0 7px 7px;
} */
.custom-modal-footer{
    display: flex !important;
    justify-content: center !important;
    padding-bottom: 15px;
}
.EditableText textarea{
    background-color: transparent !important;
    border: none;
}
.delete-btn{
    padding: 4px 14px !important;
    height: auto !important;
    font-size: 16px !important;
    background: #416B88 !important;
    border-color: #416B88 !important;
    color: white !important;
    margin-top: 16 !important;
    margin-left: 10 !important;
    border-color: #416B88 !important;
    margin-left: 10px !important;
}
.iframe{
    position: relative;
  }
  .iframe iframe{
    z-index: 999;
    position: relative;
  }
  .loader {
    border: 6px solid #e9e9e9; /* Light grey */
    border-top: 6px solid #416B88; /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.create-new-modal .ant-modal-content{
    width: 410px !important;
    margin: auto;
}
.btn-css{
    font-size: 16px !important;
    height: auto !important;
    background: #416B88 !important;
    padding: 6px 30px !important;
    color: white;
}

.btn-css:hover{
    border-color: #416B88;
    color: #416B88;
    background: transparent !important
}
.btn-css1{
    font-size: 16px;
    height: auto;
    border-color: #416B88 !important;
    padding: 6px 30px !important;
    color: #416B88 !important;
}
.analytics-btn:hover{
    background: #416B88 !important;
    color: white !important;
}


.blogs .blog-wrapper{
    transition: all .5s;
    height: 100%;
    border: 2px solid transparent;
    border-radius: 7px;
    padding: 10px;
}

.blogs .blog-wrapper:hover{
    border: 2px solid #416B88;
}
.title-hover:hover{
    text-decoration: underline !important;
}
.yes-btn{
    background: #416B88;
    border-color: #416B88;
    height: auto;
    padding: 4px 25px;
    font-size: 18px !important;
}
.yes-btn:hover{
    background-color: transparent !important;
    color: #416B87 !important;
    border-color: #416B88 !important
}
.no-btn{
    background: transparent;
    border-color: #416B88 !important;
    color: #416B87 !important;
    height: auto;
    padding: 4px 25px;
    font-size: 18px !important;
}
.no-btn:hover{
    background-color: #416B87 !important;
    color: white !important;
    border-color: #416B88 !important
}
.about-graphs{
    font-style: italic;
    color: #416B88;
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
    font-family: 'Matter-bold';
}
.about-graphs:hover{
    text-decoration: underline !important;
    cursor: pointer;
}
.card-report .ant-card-body{
    background: #EFF4F9;
    min-height: 80px;
}
.card-report .ant-card-meta-title{
    font-size: 18px;
    white-space: pre-wrap;
    line-height: 25px;
    margin-top: 10px;
}
/* .slide___3-Nqo {
    height: 200px !important;
  } */
.horizontalSlider___281Ls{
    width: 100% ;
}
/* .slideHorizontal___1NzNV{
    width: 21% !important;
} */