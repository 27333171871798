.main-wrapper {
  background-color: #e4ebf2;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.text-wrapper {
  display: flex;
  flex-direction: column;
}

.my-plan-vertical-divider {
  display: flex;
  justify-content: flex-end;
  margin: 17px 0px 17px 0px;
  height: 100%;
}
.span-text {
  font-size: 20px;
  font-family: "Matter-bold";
  display: flex;
  justify-content: center;
}
.col1-wrapper {
  margin: 26px 0px 0px 25px;
}
.col-texr-wrapper {
  font-size: 18px;
  margin-left: 8px;
}
.col-texr-wrapper.mr span {
  margin-right: 4px;
}
.col-texr-wrapper.span-text {
  color: #416b88;
}
.website-wrapper {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 2;
}
.update-date {
  margin-bottom: 15px;
  float: right;
}
.date-wrapper {
  font-size: 14px;
  color: #95a7ba;
  font-family: "Matter-bold";
  font-style: italic;
}
.tab-wrapper {
  background-color: white;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  /* height: 63vh; */
}
.profile-tab .ant-tabs-nav {
  margin: 20px 0px 0px 0px !important;
}

/* Product OverView */
.main-product-overview-wrapper {
  background-color: white;
  margin-bottom: 52px;
}
.col-icon {
  margin: 18px 0px 0px 31px;
  display: flex;
  align-items: center;
}
.text-overview {
  display: flex;
  flex-direction: column;
}
.col-text {
  margin: 0px 0px 0px 11px;
  display: flex;
  flex-direction: column;
}
.text-wrapper-title {
  font-size: 18px;
  font-family: "Matter-bold";
}
.table-wrapper {
  margin-left: 30px;
  margin-top: 14px;
  margin-right: 30px;
}
.table-css .ant-table-thead > tr > th {
  background-color: #f1f6fb !important;
  font-size: 16px;
  font-family: "Matter-bold";
}
.table-css th:nth-child(1) {
  border-bottom-left-radius: 0px !important;
}
.table-css .ant-table.ant-table-bordered {
  border: #e2ebf3 !important;
}
.table-css .ant-table.ant-table-bordered > .ant-table-container {
  border-left: 1px solid #f1f6fb !important;
  border-right: 1px solid #f1f6fb !important;
  border-top: 1px solid #f1f6fb !important;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  margin-bottom: 10px;
}
.table-css
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border-right: 3px solid #f1f6fb !important;
  border-bottom: 3px solid #f1f6fb !important;
}
.table-css
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td:nth-child(11) {
  border-right: 2px solid #f1f6fb !important;
  border-bottom: 3px solid #f1f6fb !important;
}
.table-css
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td:nth-child(1) {
  border-left: 2px solid #f1f6fb !important;
}
.table-css th.ant-table-cell {
  vertical-align: baseline;
  line-height: 21px;
}
.data-size {
  font-size: 16px;
}
.font-color {
  color: #416b88;
  font-weight: 600;
  font-family: "Matter-bold";
}
.preview-page {
  background-color: white;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  margin-bottom: 2px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.company-text {
  margin: 20px 0px 20px 10px !important;
}
.company-text-clss {
  margin: 20px 0px 0px 20px !important;
}
.title-clss {
  font-size: 18px;
  color: #416b88;
  font-family: "Matter-bold";
}
.preview-page-child {
  background-color: white;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  margin-bottom: 10px;
  height: 75vh;
}
.list-css .ant-list-item {
  border: 2px solid #f1f6fb;
  border-radius: 5px;
  background: #f1f6fb;
}
.list-css .ant-list-item {
  border-bottom: 2px solid #f1f6fb !important;
  margin-top: 10px;
}
.list-css .ant-list-pagination {
  text-align: center !important;
}
.typogrphy-text-css {
  margin-left: 20px !important;
  font-size: 20px !important;
  font-family: "Matter-bold";
}
.list-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.right-icon {
  margin-right: 20px;
  color: #416b88;
  stroke-width: 50;
  stroke: #416b88;
}
.desc-data {
  margin-left: 20px !important;
  color: #416b88;
  height: 20px;
}
.icon-css {
  margin-top: 10px;
}
.link-wrapper {
  font-size: 16px;
  color: #416b88;
  font-family: "Matter-bold";
}
.preview-page .ant-list-items {
  display: grid;
  grid-template-columns: 31.5% 31.5% 31.5%;
  gap: 20px;
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
}
.search-view-container .ant-input-search-button {
  display: none;
}
.search-view-container .ant-input-group-addon {
  background-color: #d9d9d9 !important;
}
.search-view-container .ant-input-affix-wrapper {
  padding: 9px 15px !important;
}
.search-view-container .ant-input-group-addon:last-child {
  display: none !important;
}
.search-view-container .ant-input-affix-wrapper {
  border-radius: 7px !important;
}
.empty-data.ant-empty {
 margin-top: 50px !important;
}
