.icon-support{
    display: flex;
    justify-content: center;
}
.img{
    margin-top: 40px;
}
.main-title{
    font-size: 18px;
    font-weight: 600;
    margin-top: 30px;
    font-family: 'Matter-bold';
}
.input{
    height:44px;
    font-size: 18px;
}
.btn-submit{
    background: #416B88;
    color: white;
    font-size: 18px;
    font-weight: 500;
    padding: 6px 55px;
    height: auto;
    font-family: 'Matter-bold';
}
.btn-submit:hover{
    border-color: #416B88;
    color: #416B88;
}
.child-title{
    font-size: 18px;
    font-weight: 600;
    font-family: 'Matter-bold';
}
.icon-text{
    display: flex;
}
.h2-text{
    margin-left: 9px;
    color: #727272;
    font-size: 18px;
    font-weight: 600;
}
.number{
    font-size: 18px;
    margin-left: 9px;
    font-weight: 500;
    font-family: 'Matter-bold';
}
.a{
    color: black;
}
.a:hover{
    text-decoration: underline;
    color: black;
}