@import '~antd/dist/antd.dark.css';
@import '~antd/dist/antd.compact.css';

body {
  margin: 0;
  font-family: 'Matter' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    'Matter';
} */

@font-face {
  font-family: "Matter";
  src: url("./assets/fonts/Matter-Regular.ttf");
  src: url("./assets/fonts/Matter-Bold.ttf");
  src: url("./assets/fonts/Matter-SemiBold.ttf");
  src: url("./assets/fonts/Matter-Medium.ttf");
  src: url("./assets/fonts/Matter-Light.ttf");

}

@font-face {
  font-family: "Matter-bold";
  src: url("./assets/fonts/Matter-SemiBold.ttf");
}
#menu-container .ant-menu-inline.ant-menu-root .ant-menu-item > *{
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 14px;
}
#menu-container .ant-menu-inline > li {
  margin: 25px 0 !important;
}
#menu-container .ant-menu-submenu-inline > li{
  margin-bottom: 10px !important;
}
#menu-container .ant-menu-dark .ant-menu.ant-menu-sub.ant-menu-inline > li{
  margin:  0 !important;
}

#menu-container .ant-menu-item-only-child{
  margin: 0 0 !important;  
  padding: 10px 0 10px 69px !important;
}
#menu-container #site-layout .site-layout-background {
  background: #fff;
}
/* #menu-container #site-layout {
  margin-left: 250px;
  z-index: 1;
  transition: 0.2s;
} */
#menu-container .ant-layout-sider-zero-width-trigger {
  top: 11px;
  z-index: 2;
  background: #416B87;
}
.ant-layout-header {
 background-color: #fff;
}
.sub-container {
  background: #fff;
  border-radius: 7px;
  padding: 10px;
}
.explorics-sider-menu {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5px;
}
.companySeetingTab .ant-tabs-nav-list{
  transform: translate(0px, 0px);
  background: #EFF4F9;
  margin: 10px;
  padding: 10px 10px 10px 0 ;
  border-radius: 8px;
  height: 30px;
}
.companySeetingTab .ant-tabs-ink-bar{
  right: unset !important;
  left: 0 !important;
}
.companySeetingTab .ant-tabs-tab-btn{
  font-weight: 600;
  font-size: 15px;
}
.companySeetingTab .ant-tabs-content-holder {
  border-left: none !important;
}
.companySeetingTab .ant-descriptions-item-label {
  width: 210px !important;
}
.companySeetingTab .ant-descriptions-view {
  border-radius: 8px;
}
.companySeetingTab .ant-tabs-nav-wrap{
 padding-top: 13px;
}
/* .companySeetingTab .ant-descriptions{
 padding-top: 13px;
} */
.companySeetingTab .ant-tabs-nav{
  height: 220px;
}
.companySeetingTab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #416B88 !important
}
.userTab .ant-tabs-ink-bar{
  width: 0 !important;
}
.userTab .ant-tabs-nav-list{
  background: transparent;
  border-bottom: 1px solid #D2DCE3;
  width: 100%;
  border-radius: 0;
  padding-bottom: 25px;
}
.userTab .ant-tabs-nav::before{
  border: none;
}
.userTab .ant-tabs-tab{
  transition: .5s;
  border-bottom: 2px solid transparent;
  padding-bottom: 17px;
}
.userTab .ant-tabs-tab.ant-tabs-tab-active{
  border-bottom: 2px solid #416B88;
  padding-bottom: 17px;
}
.userTab .ant-tabs-nav{
  height: auto !important;
}
.userTab .ant-tabs-nav-wrap{
  padding-top: 0;
}
.userTab .ant-tabs-nav-list{
  margin: 0 !important;
}
.companySeetingTab .suspendBtn{
  margin-top: 12px;
  height: 35px;
  width: 200px;
  background: #DD6977;
  color: white;
}

.companySeetingTab .ant-modal-body {
  padding: 30px !important;
  /* width: 500px; */
}
.suspend-company-footer .ant-modal-content {
  padding: 10px !important;
}
.editcompany .ant-drawer-header {
 background: #EFF4F9;
}
.login-data .ant-input {
  height: 40px;
  font-size: 18px;
}

.searchbar .ant-input-wrapper{
  position: relative;
}
.searchbar input{
  padding-left: 30px;
  background: #EFF4F9;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 40px;
  font-size: 16px;
}
.searchbar .ant-input-search-button{
  border-radius: 8px !important;
 height: 100%;
}
.searchbar .ant-input-group-addon{
  position: absolute;
  left: 0;
  z-index: 90;
  top: 0;
  bottom: 0;
  margin: auto 0
}
.searchbar button{
  border: none;
  background: #EFF4F9;
  border-radius: 7px 0 0 7px;
}
.searchbar button:hover{
  background-color: #EFF4F9;
  cursor: pointer;
}


/* search */
.usersearch .ant-input-wrapper{
  position: relative;
}
.usersearch input{
  padding-left: 30px;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #DEDEDE;
}
.usersearch .ant-input-group-addon{
  position: absolute;
  left: 0;
  z-index: 90;
}
.usersearch button{
  border: none;
  background: #DEDEDE;
  border-radius: 7px 0px 0px 7px;
}
.usersearch button:hover{
  cursor: pointer;
}

.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;

  border: 2px solid #416B88;
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.tabfont .ant-tabs-tab.ant-tabs-tab-active h3 {
  color: #416B88 !important; 
}
.tabfont .ant-tabs-ink-bar{
  background: #416B88 !important;
}

.companysearchbtn .ant-col.ant-form-item-control{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 8px;
  height: 44px;
}
.dropdown-text .ant-select-selection-item{
  padding: 5px !important;
}
.dropicon .ant-layout{
  color: black;
}

.tablecell .ant-table-thead > tr > th{
  background: #E8F0F9;
  height: 40px;
}
.input-modal .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 7px;
  height: 35px;
}
.company-name {
  cursor: pointer;
  color: #416B88;
  font-weight: bold;
  font-size: 14;
}
.back-to-admin-link{
  border: 1.5px #DEDEDE solid;
  border-radius: 7px;
  padding: 10px;
}
.viewingBox{
  float: unset !important;
  padding-right: 0 !important; 
  position: absolute;
  left: 98px;
  top: 21px;
}
.invitemail .ant-select-selector{
  border-radius: 7px !important;
  height: auto !important;
  padding: 5px !important;
}
.invitemebers .ant-select-selector{
  border-radius: 7px !important;
  height: 44px
}
.invitemebers .ant-select-selection-placeholder{
 line-height: 40px !important;
}
.invitemebers .ant-select-selection-item{
  line-height: 40px !important;
}
.headerText {
  font-size: 22px;
  text-align: center;
  font-weight: 200;
  padding-top: 10px;
}
.max-width {
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding-bottom: 25px;
  height: 77vh;
}
.plan-cards {
  display: flex;
  justify-content: center;
}
.plan-cards .ant-col:last-child{
  border-right: none !important
}
.plan-cards .ant-col:nth-child(1){
  margin-left: 0 !important;
}
.plan-details-header {
  height: 130px;
  text-align: left;
  display: flex;
  align-items: center;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  border-radius: 5px;
}
/* .plan-details-text {
  padding-right: 35px;
  padding-left: 20px;
  display: flex;
} */
.plan-details-title {
  padding-top: 20px;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
  font-family: "Matter-bold";
}
.entrepreneur-use-to {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 28px; */
  letter-spacing: 0em;
  text-align: left;
  padding-top: 5px;
}
.entrepreneur-use-to-item {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 17px; */
  letter-spacing: 0em;
  text-align: left;
  color: #161918;
  /* padding-left: 10px; */
}
.plan-desc {
  line-height: 18px;
  margin-top: 10px;
}
.plan-detail-price {
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #416B88;
  padding-top: 15px;
  margin-bottom: 12px;
  min-height: 85px;
}
.plan-description {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #416B88;
  padding-top: 15px;
  padding-bottom: 6px;
}
.section-select-plan-btn {
  display: flex;
  justify-content:left;
  /* padding: 0px 35px 0 0; */
}

.select-plan-btn {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  background-color: transparent;
  color: #416B88;
  margin-top: 10px;
  margin-bottom: 4px;
  bottom: 0;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
}

.select-plan-btn:hover , .select-plan-btn:focus{
  border-color: #416B88 ;
  background-color: #416B88;
  color: white;
}
.trial-box{
  background: #EFF4F9;
  border-radius: 7px;
  margin: 0 20px;
  padding: 20px;
  width: 95%;
}
.trial-box .img-wrapper{
  width: 10%;
}
.trial-box img{
  object-fit: contain;
  margin: auto;
}

.trial-box p{
  width: 90%;
  margin: auto;
  font-size: 16px;
  font-weight: 500;
}
.otp-input input{
  width: 2.5em !important;
  height: 3.5em !important;
  border-radius: 4px;
  box-shadow: none !important;
  border: 1px solid #CBCBCB;
  margin-bottom: 20px;
}
.otp .ant-form-item-control-input-content div:nth-child(1){
  justify-content: space-between;
}
.user-form{
  display: flex;
  justify-content: center;
}
.company-form{
  display: flex;
  justify-content: center;
}
.company-add-account{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 16px;
}
.invite-user{
  display: block;
  max-width: fit-content;
}
.wrapperBox {
  position: relative;
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;  /* Firefox */
}
.quicksight{
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 60%;
}
.iframeBox{
  height: calc(100vh - 64px);
}
.dashboardChart .quicksight-embedding-iframe{
  min-height: calc(100vh - 64px - 53px)!important;
}
iframe .narration-chart{
  display: flex !important;
  justify-content: center !important;
}
.billing-divider .ant-divider-horizontal{
 margin: 0 !important;
}
.thankyou-page{
  position: fixed;
  z-index: 1111;
  background-color: #fff;
  color: red;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.thankyou-modal{
  background-color: #EFF4F9;
  width: 512px;
  margin-left: calc(50% - 256px);
  border-radius: 20px;
}
.DataBox{
  background: white;
  padding: 18px 20px;
  border-radius: 7px;
  position: relative;
}
.liDot {
 text-decoration: none !important;
}
/* .option .ant-select-selector{
  border-radius: 8px !important;
  height: 35px !important;
   width: 100% !important;
} */
.option .ant-select-selection-item{
  line-height: 40px !important;
}
#menu-container .ant-menu-dark .ant-menu.ant-menu-sub.ant-menu-inline{
  background: #416B88 !important;
}

#menu-container .ant-menu-inline > .ant-menu-item , #emenu-container .ant-menu-submenu-title{
  /* height: fit-content; */
  display: flex;
  align-items: center;
}
#menu-container .ant-menu-submenu-title{
  line-height: 26px;
  align-items: center !important;
  height: auto !important;
}
.subMenuItem {
  font-size: 18px !important;
  /* height: 50px;
  padding: 5px 0 !important; */
}
.subMenuItem > div > span {
  font-size: 18px !important;
  margin-right: 15px;
}
.subMenuItem > div {
  margin-top: 0;
  margin-bottom: 0;
}
/* .subMenuItem .ant-menu{
  font-size: 13px !important;
  padding: 7px !important;
} */
.subMenuItem .ant-menu-title-content{
  font-size: 14px !important;
  padding: 0 0 0 0px !important;
}
.subMenuItem .ant-menu-item-selected{
  background-color: transparent !important;
}
.divider .ant-divider-horizontal{
  margin : 2px !important
}
#menu-container .layout {
  margin-left: 250px;
  /* z-index: 1; */
  transition: 0.2s;
}
#menu-container .layoutCollapsed{
  margin-left: 80px;
}
.table-cell{
  color: #416B88;
  font-weight: 600;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-family: 'Matter-bold';
}

.font-weight{
  font-weight: 600 !important;
  font-size: 16px;
}
.form-label .ant-form-item-label{
  font-size: 14;
}
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > *{
  font-weight: bold;
}
.dropdown-title .ant-dropdown-menu-title-content{
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}
.dropdown-title .ant-dropdown-menu{
  padding: 12px 4px;
  border-radius: 7px !important
}
.ant-dropdown{
  z-index: 9999 !important;
}
.select-style .ant-select-selector{
  height: 35px !important;
  border-radius: 7px !important;
}
.ant-drawer-header-title{
flex-direction: row-reverse;
}
.overflow-wrap {
  overflow-wrap: break-word;
}

.invitemail .ant-select-selector{
  border-radius: 7px !important;
  height: 44px
}
.invitemebers .ant-select-selector{
  border-radius: 7px !important;
  height: 44px
}
.invitemebers .ant-select-selection-placeholder{
 line-height: 40px !important;
}
.invitemebers .ant-select-selection-item{
  line-height: 40px !important;
}

/* skeleton loader */
.skeleton-box, .skeleton-square1 , .skeleton-square2{
  display: inline-block;
  position: absolute;
  overflow: hidden;
  height: 1.5em !important;
  background-color: #dddbdd;
  z-index: 1;
}
.skeleton-square1{
  height: 350px !important;
  top: 20px;
  left: 0;
}
.skeleton-square2{
  height: 350px !important;
  top: 20px;
  right: 0;
}
.skeleton-box {
  top: 390px;
  height: 350px !important;
}
.skeleton-box::after,.skeleton-square1::after, .skeleton-square2::after{
  position: absolute;
  right: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  content: '';
  height: 100%;
}


@keyframes shimmer {
  100% {
    transform: translateX(100%);
 }
}
iframe{
  z-index: -1;
}
.text-detail .ant-list-item-meta-title{
  margin-bottom: 0px !important;
  line-height: 1 !important;
}
.save-btn:hover{
  background-color: transparent !important;
  color: #416B87 !important;
  border-color: #416B88
}
.font-family-style{
  font-family: 'Matter-bold';
}
.label-detail{
  vertical-align: baseline !important;
}