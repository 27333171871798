.planIntroModal .ant-modal-content {
    border-radius: 12px;
  }
  
  .planIntroModal .ant-modal-close {
    display: none;
  }
  
  .disableModal .ant-modal-close{
    display: unset !important;
  }
  
  .planIntroModal_container {
    text-align: center;
  }
  
  .planIntroModal_introText {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #303030;
  }
  
  .planIntroModal_descText {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #252525;
  }
  
  .planIntroModal_continueBtn,
  .planIntroModal_continueBtn:hover,
  .planIntroModal_continueBtn:focus {
    width: 200px;
    height: 35px;
    background: #416B88;
    border-radius: 7px;
    color: #ffffff;
    border: none;
    box-shadow: none;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
  
  .planIntroModal_cancelBtn,
  .planIntroModal_cancelBtn:hover,
  .planIntroModal_cancelBtn:focus {
    width: 245px;
    height: 50px;
    background: transparent;
    border-radius: 100px;
    color: #000;
    border: 1px solid black;
    box-shadow: none;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
  
  .disableModal .planIntroModal_continueBtn,
  .disableModal .planIntroModal_cancelBtn{
    width: 150px;
    margin-right: 10px;
  }
  
  .disableModal .ant-modal-body{
    padding: 24px 35px;
  }