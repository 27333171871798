.dashboard-chart-skeleton{
    width: 30vw !important;
    height: 250px !important;
    border-radius: 7px !important;
  }
  .dashboard-loader{
    background-color: #fff;
    /* height: 100vh; */
    padding: 25px;
  }

  .iframe{
    position: relative;
  }
  .iframe iframe{
    z-index: 999;
    position: relative;
  }
  .loader {
    border: 6px solid #e9e9e9; /* Light grey */
    border-top: 6px solid #416B88; /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .guide-data{
    display: flex;
    justify-content: space-between;
    /* padding: 20px; */
  }
  .desc-css{
    font-size: 18px;
    font-weight: 600;
  }
  .text-desc{
    margin-left: 5px;
  }
  .searchbar .ant-input-wrapper{
    position: relative;
  }
  .searchbar input{
    padding-left: 30px;
    background: #EFF4F9;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 40px;
    font-size: 16px;
  }
  .searchbar .ant-input-search-button{
    border-radius: 8px !important;
   height: 100%;
  }
  .searchbar .ant-input-group-addon{
    position: absolute;
    left: 0;
    z-index: 90;
    top: 0;
    bottom: 0;
    margin: auto 0
  }
  .searchbar button{
    border: none;
    background: #EFF4F9;
    border-radius: 7px 0 0 7px;
  }
  .searchbar button:hover{
    background-color: #EFF4F9;
    cursor: pointer;
  }
  .table1t .ant-table-thead > tr > th{
    border-radius: 3px !important;
    background-color: #EFF4F9 !important;
    color: #416B88 !important;
    font-weight: 600;
    font-size: 16px;
  }
.table1t .ant-table-tbody > tr > td{
  font-size: 16px;
  /* padding-top: 20px;
  padding-bottom: 20px; */
  font-weight: 600;
}
.table2 .ant-table-thead > tr > th{
  border-radius: 3px !important;
  background-color: #416B88 !important;
  color: white !important;
  font-weight: 600;
  font-size: 16px;
}
.table2 .ant-table-tbody > tr > td{
  font-size: 16px;
  /* padding-top: 10px;
  padding-bottom: 10px; */
}
.table3 .ant-table-thead > tr > th{
  border-radius: 3px !important;
  background-color: #EFF4F9 !important;
  color: #416B88 !important;
  font-weight: 600;
  font-size: 16px;
}
.table3 .ant-table-tbody > tr > td{
font-size: 16px;
/* padding-top: 20px;
padding-bottom: 20px; */
}
.main-div{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pencil-style{
  color: #CBCBCB;
}