.ant-popover-buttons button{
    font-size: 16px !important;
    background: #416B88 !important;
    border-color: #416B88 !important;
    padding: 4px 15px !important;
    height: auto !important;
    color: white
}
.ant-popover-buttons button:hover{
    border-color: #416B88;
    color: #416B88;
    background: transparent !important
}
.ant-popover-message-title{
    padding-left: 0px !important;
}
.btn-underline:hover{
 text-decoration: underline !important;
}
.paragraph{
    font-size: 18px !important;
    font-weight: 500;
    margin-top: 6px;
    font-family: 'Matter-bold';
}
/* .ant-layout-sider{
height: 100vh;
position: fixed;
flex: 0 0 260px !important;
max-width: 260px !important;
min-width: 260px !important;
width: 260px !important;
} */
.ant-layout-sider a{
    white-space: pre-line;
}
.ant-layout-sider li{
    height: fit-content !important;
}
span.-title-content {
    white-space: pre-line;
    max-width: 160px;
}
.ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow{
    top: 29% !important;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow{
    margin-top: 8px !important;
}

